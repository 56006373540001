$(window).on("load", () => {
  const setHeaderBackground = () => {
    const hasHeaderFixed = $(document.body).hasClass("has-slider-as-first");

    if (hasHeaderFixed && $(window).scrollTop() > 0) {
      !$(".header-main").hasClass("header-main--bg")
        ? $(".header-main").addClass("header-main--bg")
        : null;
    } else {
      $(".header-main").hasClass("header-main--bg")
        ? $(".header-main").removeClass("header-main--bg")
        : null;
    }
  };

  const lockScroll = () => {
    var $html = $("html");
    var $body = $("body");
    var initWidth = $body.outerWidth();
    var initHeight = $body.outerHeight();

    var scrollPosition = [
      self.pageXOffset ||
        document.documentElement.scrollLeft ||
        document.body.scrollLeft,
      self.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop,
    ];
    $html.data("scroll-position", scrollPosition);
    $html.data("previous-overflow", $html.css("overflow"));
    $html.css("overflow", "hidden");
    window.scrollTo(scrollPosition[0], scrollPosition[1]);

    var marginR = $body.outerWidth() - initWidth;
    var marginB = $body.outerHeight() - initHeight;
    $body.css({ "margin-right": marginR, "margin-bottom": marginB });
  };

  const unlockScroll = () => {
    var $html = $("html");
    var $body = $("body");
    $html.css("overflow", $html.data("previous-overflow"));
    var scrollPosition = $html.data("scroll-position");
    window.scrollTo(scrollPosition[0], scrollPosition[1]);

    $body.css({ "margin-right": 0, "margin-bottom": 0 });
  };

  setHeaderBackground();
	
  $(window).on("scroll", setHeaderBackground);

  $(".navigation-box__list ").on(
    "click",
    ".navigation-box__item--parent>a",
    function (e) {
      e.preventDefault();

      const itemWrapper = $(this).parent("li");

      if (itemWrapper.hasClass("show")) {
        return itemWrapper.removeClass("show").find("ul:first").slideUp();
      }

      itemWrapper.addClass("show").find("ul:first").slideDown();
    }
  );

  $(".navigation-box__exit, .hamburger").on("click", function (e) {
    e.preventDefault();
    $(".hamburger").toggleClass("hamburger--active");
    $(".navigation-box").toggleClass("navigation-box--active");
    $(".hide-bars-nav").toggleClass("hidden");

    $(".hamburger--active").length ? lockScroll() : unlockScroll();
  });


  if( $('.contact-page').length ){

    $( '.contact-bottom', '.contact-page' ).parent('.footer__item').addClass('hidden-title')

  }

  if( $('.ti-reviews-container-wrapper').length ){
    $( '.ti-review-item:nth-child(n + 4)', '.ti-reviews-container-wrapper').remove();
}

  const header = $( '.header-main' )

  $(window).bind('mousewheel', function(event) {
    if (event.originalEvent.wheelDelta >= 0) {
      if( ! header.hasClass('header-main--sticky') ) {
        header.addClass('header-main--sticky')
      }

    }
    else {
      
      if( header.hasClass('header-main--sticky') ) {
        header.removeClass('header-main--sticky')
      }

    }
  });

  if( $('.team-picker').length ){

    $('.team-picker').on('click','.button--visit', function(e){
      e.preventDefault();
      const wrapper = $(this).parents('.employer-single-content'),
            modal = $('.modal');


      modal.find('[name="expert-name"]').val(wrapper.find('.employer-single-header__title').text())
      modal.addClass('modal--active')
    });

    $('.modal-content__header-exit').on('click', function(e){
      e.preventDefault();
      $('.modal').removeClass('modal--active')
    })

  }


  if( $('.counters').length ){


    if(window.matchMedia('(min-width: 1024px)').matches){

      $(".counters").on( {

        mouseover: function(event) {
          if( ! $(this).hasClass('hover') ){
            $(this).addClass('hover')
          }
        },
        
        mouseout: function(event) {
          if(  $(this).hasClass('hover') ){
            $(this).removeClass('hover')
          }
        }

      }, '.counters__single' );

  }else{

    $(".counters").on( {

      click: function(event) {
        $(".counters__single").not( this ).removeClass('hover')
        $(this).toggleClass( 'hover' )
      },


    }, '.counters__single' );

  }

    



  }

  if( $('iframe').length ){

    $('iframe').each( function( ){

      if( !  $('iframe').attr('src').indexOf('znanylekarz') ){
        return;
      }

      $(this).on("load", function(e){
        console.log(  'hehehe')
        $(this).contents().find(".certificate-widget").attr("style","width:100%;height:100%")
        console.log( $(this) )

      });

      var head = $(this).contents().find("head");

      var css = '<style type="text/css">' +
                '.certificate-widget{ max-width: 100%!important }; ' +
                '</style>';

      jQuery(head).append(css);

      $(this).contents().find(".certificate-widget").attr("style","width:100%;height:100%")

      $(this).css( 'width', '100%' );

    }  )


  }
  
});
